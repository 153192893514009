import logo from './logo.svg';
import './App.css';
import styles from './Home.module.css'
import Aos from 'aos';
import "aos/dist/aos.css";
import React, { Component, setState, useEffect } from "react";
import { render } from '@testing-library/react';

export default class App extends Component {
  constructor() {
    super();
    this.rssRef = React.createRef()
    this.state = {
      rss: ''
    }
  }
  componentDidMount() {
    Aos.init(
      {
        duration: 2000
      }
    );
    Aos.refresh();

    this.fetchRSS()
  }

  fetchRSS() {
    const RSS_URL = `https://decrypt.co/feed`;

    fetch(RSS_URL)
      .then(response => response.text())
      .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
      .then(data => {
        const items = data.querySelectorAll("item");
        let html = ``;
        for (let i = 0; i < 10; i++) {
          html += `
      <center>
        <article>
          <img src="${items[i].querySelector("link").innerHTML}/image/large.png" alt="">
          <h2>
            <a href="${items[i].querySelector("link").innerHTML}" target="_blank" rel="noopener">
              ${items[i].querySelector("title").innerHTML}
            </a>
          </h2>
        </article>
        </center>
      `;
        }
        this.setState({ rss: html })
        //document.body.insertAdjacentHTML("beforeend", html);
      });
  }


  executeScroll = () => this.rssRef.current.scrollIntoView()

  render() {


    return (
      <div className={styles.container}>

        <main className={styles.main}>
          <span data-aos="fade-zoom-in" onClick={this.executeScroll}>
            <img src="/onlybands-lightyt.png" alt="" width={400} height={400} />
          </span>
          <div onClick={this.executeScroll} data-aos="fade-zoom-in" className={styles.description} >
            Stop asking why and start asking how. Scroll down ↓ for the latest in DeFi news.
          </div>


          <div data-aos="fade-zoom-in" className={styles.grid}>
            <a
              href="https://onlyfans.com/onlybands.official"
              className={styles.card}
            >
              <h2>*FREE* Onlyfans &rarr;</h2>
            </a>

            <a
              href="https://www.tiktok.com/@onlybands.official"
              className={styles.card}
            >
              <h2>TikTok &rarr;</h2>
            </a>

            <a
              href="https://www.instagram.com/onlybands.official/"
              className={styles.card}
            >
              <h2>Instagram &rarr;</h2>
            </a>

            <a
              href="https://www.youtube.com/channel/UCK2d6_6qcKgIj7OXPHHG6zA"
              className={styles.card}
            >
              <h2>Youtube &rarr;</h2>
            </a>
          </div>
        </main>
        <a
          href="https://www.tiktok.com/@onlybands.official"
        >
          <footer className={styles.footer}>
            <span className={styles.logo}>
              <img src="/bandsculture.png" alt="" width={300} height={100} />
            </span>
          </footer>
        </a>
        <br />
        <div id="btm" className="content" dangerouslySetInnerHTML={{ __html: this.state.rss }}></div>
<br/>
        <center>
          <a ref={this.rssRef} href="https://decrypt.co/"><h2>Read more &rarr;</h2></a>
        </center>
      </div>
    );
  }
}
